import React, { useState, useEffect } from 'react';
import './about.css';
import Portrait from '../assets/portrait-no-bg-color.png';
import DarkPortrait from '../assets/portrait-dark-no-bg-color.png';

const About = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const checkDarkMode = () => {
    setIsDarkMode(document.body.classList.contains('dark'));
  };

  useEffect(() => {
    checkDarkMode();
    const observer = new MutationObserver(checkDarkMode);
    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });
    return () => observer.disconnect();
  }, []);

  return (
    <section className="about">
      <p><img src={isDarkMode ? DarkPortrait : Portrait} alt="portrait" />Ahoy! I'm Wylie—a developer who loves creating things. Whether it's a digital experience or something tangible, I enjoy the process of bringing ideas to life with care and creativity. Staying curious keeps my work fresh, and I'm always excited to learn new tools and techniques to improve what I make.</p>
      <p>When I'm not building things, I'm probably spending time with my family—my partner, my kid, and our little zoo of pets: a dog, two cats, and some sea monkeys (chickens are on my wish list!). I'm an artist and a big fan of Lego, campfires, and outdoor adventures—hiking, kayaking, or just getting lost in a good book.</p>
      <p>I'm grounded by values like social justice, caring for the earth, curiosity, generosity, and honesty. These guide both my work and my life, shaping how I collaborate, create, and grow. If you want to know more about me or my work, let's connect!</p>
    </section>
  );
};

export default About;